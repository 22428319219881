import React from 'react';
import GEElectricLogo from '../../images/GEElectricLogo.png';
import './about.css';

const About: React.FC = () => {
  return (
    <div id="master">
      <div className="jumbotron-fluid" style={{minHeight: '100vh'}}>
        <br />
        <div className="container fade-in-text">
          <h1 className="display-4">Welcome to GE Electric</h1>
          <p className="lead">Gerardo Abaunza | Master Electrician | Licensed and Insured</p>
        </div>
        <hr className="my-4" />
        <div className="container">
          <div className="row">
            <div className="col-lg">
              <img src={GEElectricLogo} className="w-100 fade-in-image" alt="GE Electric"></img>
              <br />
              <br />
            </div>
            <div className="col-lg fade-in-text-third">
              <h5>GE Electric has been serving the Northern Virginia area since 2010</h5>
              <br />
              <h5>
              GE Electric provides a wide and full range of electrical services for both residential and commercial 
              buildings and can guarantee effectiveness and efficiency. With all our work being guaranteed and 
              fully insured, our primary goal is to make your 
              home or business's systems safe, elegant, and easy to use. 
              Exceeding our customer's expectations is our number one priority!
              </h5>
              <br />
              <br />
              <h2 className="d-flex justify-content-center" style={{fontWeight: 'bold'}}>Call Today at 703-347-5068</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About;