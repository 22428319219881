import React, { useState, useEffect } from 'react';
import { StyledServices, ServicesButtonStyled, ServicesContainer, ServicesHeaderStyled, ServicesLinkStyled } from './services.styled';

interface Props {

}

const Services: React.FC<Props> = () => {
    const [dClicked, setDClicked] = useState<boolean>(true);
    const [cClicked, setCClicked] = useState<boolean>(false);
    const [gClicked, setGClicked] = useState<boolean>(false);
    const [recentClick, setRecentClick] = useState<number>(0);

    useEffect(() => {
        if(recentClick === 0) {
            setCClicked(false);
            setGClicked(false);
        } else if(recentClick === 1) {
            setDClicked(false);
            setGClicked(false);
        } else if(recentClick === 2) {
            setDClicked(false);
            setCClicked(false);
        }
    }, [dClicked, cClicked, gClicked, recentClick])

    return (
        <StyledServices>
            <ServicesHeaderStyled>
                <h2>Services</h2>
                <hr />
            </ServicesHeaderStyled>
            <ServicesLinkStyled>
                <ServicesButtonStyled clicked={dClicked} onClick={() => {setDClicked(true); setRecentClick(0);}}>General Services</ServicesButtonStyled>
                <ServicesButtonStyled clicked={cClicked} onClick={() => {setCClicked(true); setRecentClick(1);}}>Commercial Lights</ServicesButtonStyled>
                <ServicesButtonStyled clicked={gClicked} onClick={() => {setGClicked(true); setRecentClick(2);}}>Special Lights</ServicesButtonStyled>
            </ServicesLinkStyled>
            <ServicesContainer>
                {dClicked && 
                <>
                    <h3 className="pt-3 ps-3">General Services</h3>
                    <ul>
                        <li>New Construction Wiring</li>
                        <li>Home Renovation Wiring</li>
                        <li>New Addition Wiring</li>
                        <li>New Circuits for microwaves, freezers, washers, dryers, and other appliances</li>
                        <li>Replacement Light Fixtures</li>
                        <li>New Dedicated Lines 120 volts / 240 volts</li>
                        <li>Panel changes / Upgrades / Sub Panels</li>
                        <li>Breaker & Circuit Replacements</li>
                        <li>Switches and Outlets</li>
                        <li>Dimmer Switches and GFI Outlets</li>
                        <li>Ceiling Fan Installations</li>
                        <li>Smoke Detectors</li>
                        <li>Repairing and Troubleshooting Any Code Violation Warnings</li>
                    </ul>
                </>}
                {cClicked && 
                <>
                    <h3 className="pt-3 ps-3">Commercial Lights</h3>
                    <ul>
                        <li>Warehouse Lighting</li>
                        <li>Pole Lighting</li>
                        <li>Sign Lighting</li>
                    </ul>
                </>}
                {gClicked && 
                <>
                    <h3 className="pt-3 ps-3">Special Lights</h3>
                    <ul>
                        <li>Recessed Lights</li>
                        <li>Chandelier Installations</li>
                        <li>Pendant Lights / Vanity Lights</li>
                        <li>Sensor Lights</li>
                        <li>Landscape Lights</li>
                    </ul>
                </>}
            </ServicesContainer>
        </StyledServices>
    );
}

export default Services;