import React from 'react'
import { ContactHeaderStyled, StyledContact } from './contact.styled';
import { Phone, Location, Envelope} from 'akar-icons';

let Contact: React.FC = () => {
    return (
        <div id="page">
            <StyledContact>
                <ContactHeaderStyled>
                    <h2>Contact</h2>
                    <hr />
                </ContactHeaderStyled>
                <div>
                    <br />
                    <br />
                    <h2>Thanks for visiting! Have any questions or concerns?</h2>
                    <h4>Feel free to contact us using any of the methods below:</h4>
                    <br />
                    <br />
                </div>
                <div className="row">
                    <div className="col-lg big">
                    <br />
                        <h4><Phone size={24} /> 703-347-5068</h4>
                        <br />
                    </div>
                    <div className="col-lg big">
                    <br />
                        <h4><Envelope size={24} /> geelectric2000@yahoo.com</h4>
                        <br />
                    </div>
                    <div className="col-lg big">
                    <br />
                        <h4><Location size={24} />4151 Meadowland Court, Chantilly VA 20151</h4>
                        <br />
                    </div>
                </div>
            </StyledContact>
        </div>
    )
}

export default Contact;