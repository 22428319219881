import styled from 'styled-components';

export const StyledLogo = styled.a`
  color: #fcec03;
  text-decoration: none;
  font-weight: 800;
  font-size: 1.7rem;
  padding-left: 1rem;
  span {
    font-weight: 300;
    font-size: 1.3rem;
  }
  
  &:hover {
      color: #fcec03;
      opacity: 0.8;
  }
`;