import React from 'react';
import { StyledNav } from './nav.styled';

interface Props {
    children: any;
}

const Nav: React.FC<Props> = ({children}) => {
    return (
        <StyledNav id="about">
            {children}
        </StyledNav>
    )
}

export default Nav;