import React, { useState } from 'react';
import Hamburger from './hamburger';
import Logo from './logo';
import Menu from './menu';
import MenuLink from './menuLink';
import Nav from './nav';

interface Props {
    color?: string;
    logoText?: string;
    routes: {title: string, to: string}[];
    routesColor?: string;
}

const NavBar: React.FC<Props> = ({color, logoText, routes, routesColor}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <Nav>
            <Logo link="#home" setOpened={setIsOpen}>
                GE ELECTRIC
            </Logo>
            <Hamburger setOpened={setIsOpen} opened={isOpen} />
            <Menu isOpen={isOpen}>
                {routes.map((route, index) => {
                    return (
                        <MenuLink link={route.to} key={index} setIsOpen={setIsOpen}>
                            <span className="me-1">0{index+1}.</span>{route.title.toUpperCase()}
                        </MenuLink>
                    )
                })}
            </Menu>
        </Nav>
    )
}

export default NavBar;