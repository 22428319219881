import React from 'react';
import NavBar from './components/navbar/navbar';
import './App.css';
import About from './components/about/about';
import Services from './components/services/services';
import Contact from './components/contact/contact';

const App: React.FC = () => {
  return (
    <div>
      <div id="home"></div>
      <NavBar routes={[
          {title: "HOME", to:"#home"},
          {title: "SERVICES", to:"#services"},
          {title: "CONTACT", to: "#contact"}
      ]}/>
      <div id="main-content">
        <About />
        <div id="services" className="mb-2"></div>
        <Services />
        <div id="contact" className="mb-2"></div>
        <Contact />
      </div>
    </div>
  );
}

export default App;