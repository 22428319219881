import React from 'react';
import {StyledMenuLink} from './menuLink.styled';

interface Props {
    children: any
    link: string;
    setIsOpen: (val: boolean) => void;
}

const MenuLink: React.FC<Props> = ({children, link, setIsOpen}) => {

    return (
        <StyledMenuLink href={link} target={"_self"} rel="noreferrer" onClick={() => setIsOpen(false)}>
            {children}
        </StyledMenuLink>
    )
}

export default MenuLink;